
import React, { useState, useMemo } from 'react'
import Dashboard from '../pages/Dashboard'
import Recommendations from '../pages/Recommendations'
import Inventories from '../pages/Inventories'
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import Upload from '../pages/Upload';
import SpycareUpload from '../pages/SpycareUpload';
import Nav from './Nav';
import FileManager from '../pages/FileManager';
import KickoffMeeting from '../pages/KickoffMeeting';
import MyTeam from '../pages/MyTeam';
import SpycareHelpDesk from '../pages/SpycareHelpDesk';
import HandleJob from './HandleJob';
import HandleSpycareJob from './HandleSpycareJob';
import useLocalStorage from '../hooks/useLocalStorage';
import ContractTracker from '../pages/ContractTracker';
import RecommendationSummary from '../pages/RecommendationSummary';
import HealthCheckSummary from '../pages/HealthCheckSummary';
import HealthCheckRecommendations from '../pages/HealthCheckRecommendations';
import HealthCheckHistory from '../pages/HealthCheckHistory';
import Login from '../pages/Login';
import JobProvider from './JobProvider';
import PortalProvider from './PortalProvider';
import AuthProvider from './AuthProvider';
import SavingsProvider from './SavingsProvider';
import AccessProvider from './AccessProvider';
import SourcingManagement from '../pages/SourcingManagement';

function App() {
  const [portal, setPortal] = useLocalStorage('portal')
  const [jobId, setJobId] = useLocalStorage('jobId')
  const [spycareJobId, setSpycareJobId] = useLocalStorage('spycareJobId')
  const [recAccess, setRecAccess] = useLocalStorage('recAccess')
  const [invAccess, setInvAccess] = useLocalStorage('invAccess')
  const [ibaAccess, setIbaAccess] = useLocalStorage('ibaAccess')
  const [navSavings, setNavSavings] = useState(0)
  const jobs = useMemo(() => ({ jobId, spycareJobId }), [jobId, spycareJobId])
  const access = useMemo(() => ({ recAccess, invAccess, ibaAccess }), [recAccess, invAccess, ibaAccess])
  const savings = useMemo(() => ({ navSavings, setNavSavings }), [navSavings, setNavSavings])

  return (
    <AuthProvider setJobId={setJobId} setSpycareJobId={setSpycareJobId}>
      <JobProvider value={jobs}>
        <AccessProvider value={access} setRecAccess={setRecAccess} setInvAccess={setInvAccess} setIbaAccess={setIbaAccess}>
          <PortalProvider value={portal} setPortal={setPortal}>
            <SavingsProvider value={savings}>
              <Router>
                <Nav setPortal={setPortal} />
                <Switch>
                  <Route path="/file-manager" component={FileManager} />
                  <Route path="/inventory" component={Inventories} />
                  <Route path="/recommendation-summary" component={RecommendationSummary} />
                  <Route path="/upload" component={Upload} />
                  <Route path="/spycare-upload" component={SpycareUpload} />
                  <Route path="/kickoff-meeting" component={KickoffMeeting} />
                  <Route path="/my-team" component={MyTeam} />
                  <Route path="/job/:jobId" component={() => <HandleJob setJobId={setJobId} setSpycareJobId={setSpycareJobId} setPortal={setPortal} setRecAccess={setRecAccess} setInvAccess={setInvAccess} setIbaAccess={setIbaAccess} />} />
                  <Route path="/spycare-job/:jobId" component={() => <HandleSpycareJob setJobId={setJobId} setSpycareJobId={setSpycareJobId} setPortal={setPortal} setRecAccess={setRecAccess} setInvAccess={setInvAccess} setIbaAccess={setIbaAccess} />} />
                  <Route path="/help-desk" component={SpycareHelpDesk} />
                  <Route path="/contract-tracker" component={ContractTracker} />
                  <Route path="/sourcing-management" component={SourcingManagement}/>
                  <Route path="/recommendation/health-check/:healthCheckId" component={HealthCheckRecommendations} />
                  <Route path="/recommendation/health-check" component={HealthCheckRecommendations} />
                  <Route path="/recommendation" component={Recommendations} />
                  <Route path="/login" component={Login} />
                  <Route path="/" component={Dashboard} />
                </Switch>
              </Router>
            </SavingsProvider>
          </PortalProvider>
        </AccessProvider>
      </JobProvider>
    </AuthProvider>
  )
}

export default App

const formatAsCurrency = (value) => {
  if (typeof value !== 'number') {
    return null
  }

  return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
}

const formatTeam = (teamObj) => {
  const teamMemberOrder = {
    'IPM Presenter': 1,
    'Head of IPM': 2,
    'Secondary IPM': 3,
    'Outside Sales Rep': 4,
    'Inside Rep': 5,
    'Project Manager': 6,
    'Project Manager 2': 7,
    'IPM Support': 8,
    'Auditor': 9,
    'Audit Support': 10,
    '': 11,
  }

  teamObj.headOfWireless2 = {
    username: 'Erica Lowell',
    firstName: 'Erica',
    lastName: 'Lowell',
    email: 'elowell@spyglass.net',
    jobId: 0,
    phone: '440 552 8854',
    title: 'Wireless Manager',
    url: '/erica-cowles.jpg'
  }

  return Object.keys(teamObj).map(m => {
    const member = { ...teamObj[m] }
    if (member.username === 'Amanda Liska') {
      member.username = 'Kyle Ogilbee';
      member.firstName = 'Kyle';
      member.lastName = 'Ogilbee';
      member.email = 'kogilbee@spyglass.net';
      member.jobId = 0;
      member.phone = '216 570 1679';
      member.title = 'Wireless Manager'
      member.url = '/kyle-ogilbee.jpg'
    }
    switch (m) {
      case 'ipmPresenter':
        member.roleName = 'IPM Presenter'
        break
      case 'outsideSalesRepo':
        member.roleName = 'Outside Sales Rep'
        break
      case 'secondaryIpm':
        member.roleName = 'Secondary IPM'
        break
      case 'headOfIpm':
        member.roleName = 'Head of IPM'
        break
      case 'headOfWireless':
        member.roleName = 'Project Manager'
        break
      case 'headOfWireless2':
        member.roleName = 'Project Manager'
        break
      case 'ipmSupport':
        member.roleName = 'IPM Support'
        break
      case 'auditor':
        member.roleName = 'Auditor'
        break
      case 'auditSupport':
        member.roleName = 'Audit Support'
        break
      case 'bdRep':
        member.roleName = 'Inside Rep'
        break
      default:
        member.roleName = ''
    }

    return member
  }).filter(m => m.email).sort((a, b) => teamMemberOrder[a.roleName] - teamMemberOrder[b.roleName])
    // Remove duplicate usernames
    .filter((m, index, self) => index === self.findIndex((t) => (
      t.username === m.username
    )));
}

const formatSpyCareTeam = (teamObj) => {
  const teamMemberOrder = {
    'Account Manager': 1,
    'Auditor': 2,
    'Audit Support': 3,
    'Outside Sales Rep': 4,
    'Account Manager Support': 5,
    '': 6,
  }

  return Object.keys(teamObj).map(m => {
    const member = { ...teamObj[m] }
    switch (m) {
      case 'ipmPresenter':
        member.roleName = 'Account Manager'
        break
      case 'outsideSalesRepo':
        member.roleName = 'Outside Sales Rep'
        break
      // case 'secondaryIpm':
      //   member.roleName = 'Secondary IPM'
      //   break
      // case 'headOfIpm':
      //   member.roleName = 'Head of IPM'
      //   break
      // case 'headOfWireless':
      //   member.roleName = 'Project Manager'
      //   break
      case 'ipmSupport':
        member.roleName = 'Account Manager Support'
        break
      case 'auditor':
        member.roleName = 'Auditor'
        break
      case 'auditSupport':
        member.roleName = 'Audit Support'
        break
      default:
        member.roleName = ''
    }

    if (member.username === 'Mike Farrell') {
      teamMemberOrder[member.roleName] = 0;
    }

    return member
  }).filter(m => m.email).sort((a, b) => teamMemberOrder[a.roleName] - teamMemberOrder[b.roleName])
    // Remove duplicate usernames
    .filter((m, index, self) => index === self.findIndex((t) => (
      t.username === m.username
    )))
    .filter((m) => m.roleName !== '');
}

const getProjectStatus = (jobStatus) => {
  const jobStatusMap = {
    1: 'materials',
    3: 'kom',
    5: 'analysis',
    6: 'analysis',
    7: 'analysis',
    9: 'sof',
    10: 'decisions',
    12: 'iba',
    14: 'iba',
    19: 'iba',
    20: 'iba',
    22: 'materials',
    23: 'materials',
    24: 'materials',
  }

  return jobStatusMap[jobStatus]
}

const mapTypeToId = (names) => {
  const recTypeMap = {
    'unused lines': 1,
    'excessive lines': 2,
    'did trunks': 3,
    'fx lines': 4,
    'isdn bri': 5,
    'remote call forward': 6,
    'pri / voice circuit scalability': 7,
    'wire maintenance': 8,
    'cramming': 9,
    'landline features': 10,
    'advertising & listings': 11,
    'analog circuits': 12,
    'miscellaneous data connections': 13,
    'excessive internet': 14,
    'unused wireless devices': 15,
    'wireless features': 16,
    'wireless account consolidation omit?': 17,
    'tax': 18,
    'excessive line pricing': 19,
    'excessive pri pricing': 20,
    'service upgrade': 21,
    'duplicate line billing': 22,
    'extra surcharges': 23,
    'nars scalability': 24,
    'retro': 25,
    'excessive data pricing': 26,
    'excessive long distance pricing': 27,
    'excessive wireless pricing': 28,
    'historical core4': 30,
    'historical wireless': 31,
    'long distance fees': 32,
    'cloud elimination': 41,
    'cloud cost reduction': 42,
    'fax line elimination': 43,
    'secondary internet connections': 44,
    'excessive fax line pricing': 45
  }

  if (typeof (names) === 'string') {
    return recTypeMap[names.toLowerCase()]
  }

  return names.map((name) => recTypeMap[name.toLowerCase()])
}

const groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

const formatDocs = ((apiDocs) => {
  const docTypes = [
    { id: 1, name: 'SpyGlass Agreements' },
    { id: 2, name: 'Carrier Invoices' },
    { id: 3, name: 'Carrier Contracts' },
    { id: 4, name: 'Customer Uploads' },
    { id: 5, name: 'Miscellaneous' },
    { id: 6, name: 'New' },
    { id: 7, name: 'Audit Materials' },
    { id: 8, name: 'Resources' }
  ];
  return apiDocs.map(doc => {
    return {
      ...doc,
      documentType: docTypes.find(dt => dt.id === doc.documentTypeId).name
    }
  });
});

const formatSpycareDocs = ((apiDocs) => {
  const docTypes = [
    { id: 1, name: 'SpyCare Agreements' },
    { id: 2, name: 'Carrier Invoices' },
    { id: 3, name: 'Carrier Contracts' },
    { id: 4, name: 'Customer Uploads' },
    { id: 5, name: 'Miscellaneous' },
    { id: 6, name: 'New' },
    { id: 7, name: 'Audit Materials' },
    { id: 8, name: 'Resources' }
  ];
  return apiDocs.map(doc => {
    return {
      ...doc,
      documentType: docTypes.find(dt => dt.id === doc.documentTypeId).name
    }
  });
});

const formatHealthCheckAsJob = (healthCheck) => {
  const recs = healthCheck.recommendations || []
  const mappedRecs = recs.reduce((map, rec) => {
    if (Object.keys(map).includes(rec.recommendationType.recommendationTypeId.toString())) {
      map[rec.recommendationType.recommendationTypeId].recs.push(rec)
    } else {
      map[rec.recommendationType.recommendationTypeId] = { type: rec.recommendationType, recs: [rec] }
    }
    return map
  }, {})

  const summaries = Object.keys(mappedRecs).map(recTypeId => (
    {
      recommendationType: mappedRecs[recTypeId].type,
      recommendations: mappedRecs[recTypeId].recs,
    }
  ))

  const job = { recSummaries: summaries, forwardTerms: 12, retroactiveTerms: 100, healthCheckId: healthCheck.healthCheckId }
  return job
}

// Adapted from https://stackoverflow.com/questions/13627308/add-st-nd-rd-and-th-ordinal-suffix-to-a-number
const appendOrdinalSuffix = (i) => {
  const j = i % 10,
  k = i % 100;
  if (j === 1 && k !== 11) {
    return i + "st";
  }
  if (j === 2 && k !== 12) {
    return i + "nd";
  }
  if (j === 3 && k !== 13) {
    return i + "rd";
  }
  return i + "th";
}

const deleteGrid = (gridId, callback = () => { }, token) => {
  fetch(`${process.env.REACT_APP_API_URL}/data/grid/${gridId}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
    .then(res => res.json())
    .then(result => {
      callback()
    })
}

const compareStrings = (a, b) => {
    if (a.toLowerCase() > b.toLowerCase()) {
      return 1;
    }

    if (a.toLowerCase() < b.toLowerCase()){
      return -1;
    }

    return 0;
}

export {
  formatAsCurrency,
  formatHealthCheckAsJob,
  formatTeam,
  formatSpyCareTeam,
  getProjectStatus,
  mapTypeToId,
  groupBy,
  formatDocs,
  formatSpycareDocs,
  appendOrdinalSuffix,
  deleteGrid,
  compareStrings
}
